import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';
const WIDGET_STAGE_API_URL = 'https://backend-stage.estate.hart-digital.com';
const HIDE_PRELOADER_DELAY_AFTER_LOAD = 500;

const hidePreloader = () => {
  const preloader = document.querySelector('.widget-preloader-frame');
  if (preloader) {
    preloader.classList.add('hidden');
  } else {
    console.error('Preloader not found!')
  }
};

const createWidget = async () => {
  const searchParams = (new URL(document.location)).searchParams;
  const env = searchParams.get('env');
  const widgetApiHandler = new ApiStore(env?.toLowerCase() === 'stage' ? WIDGET_STAGE_API_URL : WIDGET_API_URL);

  const planId = searchParams.get('id');
  const crmPlanId = searchParams.get('crmPlanId');

  const primaryCameraPointId = searchParams.get('primaryCameraPointId');

  const planData = crmPlanId ? await widgetApiHandler.loadCrmWidgetData(crmPlanId) : await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    tabs: ['panorama', 'rotation'],
    primaryCameraPointId,
  }

  if (options.branding && options.branding.name !== null) {
    document.title = options.branding.name;
  }

  new Widget('#widget', options);
};

createWidget('https://www.test.ru/').then(() => {
  setTimeout(hidePreloader, HIDE_PRELOADER_DELAY_AFTER_LOAD);
});
